import { loadingController } from '@ionic/vue'
import { defineStore } from 'pinia'

import {
  getSyncData,
  getSortings,
  getDestinations,
  getReasons,
  getTypes,
  syncMasterData,
  getWholesalers,
  getObservations,
  getClients,
  getDepreciations,
  getProducts,
  getLots,
  getLaboratory,
  setLaboratory,
  deleteDB
} from '@/db/sqlite'
import {
  Client,
  ClientsFilter,
  Depreciation,
  Destination,
  LotNumber,
  LotNumberFilter,
  Observation,
  Product,
  ProductFilter,
  Reason,
  Sorting,
  Type,
  User,
  Wholesaler
} from '@/interfaces'
import { SYNC_TIME_PERIOD } from '@/constants'
import { roundToNearestMinute } from '@/composables'
import { useRefundStore } from '@/store/refund'
import { useAuthStore } from '@/store/auth'

type SyncState = {
  nextSyncDate: number
  lastTimeSync: number

  versions: unknown[]
  clients: Client[]
  lots: LotNumber[]
  products: Product[]
  types: Type[]
  reasons: Reason[]
  destinations: Destination[]
  sortings: Sorting[]
  wholesealers: Wholesaler[]
  observations: Observation[]
  depreciations: Depreciation[]
}

export const useSyncStore = defineStore({
  id: 'sync',
  persist: true,
  state: (): SyncState => ({
    nextSyncDate: 0,
    lastTimeSync: 0,

    versions: [],
    lots: [],
    clients: [],
    products: [],
    types: [],
    reasons: [],
    destinations: [],
    sortings: [],
    wholesealers: [],
    observations: [],
    depreciations: []
  }),
  actions: {
    clearLotsArray() {
      this.lots = []
    },

    async getVersions() {
      try {
        this.versions = await getSyncData()
      } catch (e) {
        throw new Error('Error while getting versions')
      }
    },

    async fillVersions() {
      try {
        this.versions = await syncMasterData()
      } catch (e) {
        console.log(e)
        throw new Error('Error while syncing data')
      }
    },

    async checkLaboratory(user: User) {
      const laboratory = await getLaboratory()

      if (laboratory && laboratory.label !== user.laboratory) {
        await deleteDB()
      }

      await setLaboratory(user.laboratory)
    },

    canSync() {
      const authStore = useAuthStore()

      if (
        authStore.isUserLoggedIn &&
        navigator.onLine &&
        !window.location.href.includes('/new-refund') &&
        !window.location.href.includes('/new-sample')
      ) {
        const currentDatetime = new Date().getTime()
        const nextSyncDatetime = this.nextSyncDate

        return currentDatetime >= nextSyncDatetime
      }

      return false
    },

    async performSync() {
      const loading = await loadingController.create({
        spinner: 'crescent',
        message: 'Sincronizando datos...',
        cssClass: 'loading-spinner'
      })
      await loading.present()

      try {
        await this.fillVersions()
        await this.fillSyncState()

        const refundStore = useRefundStore()
        const pendingRefunds = await refundStore.getLocalItemsWithLines()

        for (const refund of pendingRefunds) {
          await refundStore.postCompleteRefund(refund)
        }

        await refundStore.getLocalItems()
        await refundStore.getItems(true)

        const now = new Date()
        this.lastTimeSync = now.getTime()
        this.nextSyncDate = roundToNearestMinute(new Date(now.getTime() + SYNC_TIME_PERIOD * 60 * 1000)).getTime()
      } catch (e) {
        console.log(e)
        throw new Error('Error while performing sync')
      } finally {
        await loading.dismiss()
      }
    },

    async fillSyncState() {
      await this.getDestinations()
      await this.getObservations()
      await this.getReasons()
      await this.getSortings()
      await this.getTypes()
      await this.getWholesalers()
      await this.getDepreciations()
    },

    async getClients(filters: ClientsFilter) {
      try {
        this.clients = await getClients(filters)
      } catch (e) {
        throw new Error('Error while getting clients')
      }
    },

    async getProducts(filters: ProductFilter) {
      try {
        this.products = await getProducts(filters)
      } catch (e) {
        throw new Error('Error while getting products')
      }
    },

    async getLots(filters: LotNumberFilter) {
      try {
        this.lots = await getLots(filters)
      } catch (e) {
        throw new Error('Error while getting lots')
      }
    },

    async getTypes() {
      try {
        this.types = await getTypes()
      } catch (e) {
        throw new Error('Error while getting types')
      }
    },

    async getObservations() {
      try {
        this.observations = await getObservations()
      } catch (e) {
        throw new Error('Error while getting observations')
      }
    },

    async getWholesalers() {
      try {
        this.wholesealers = await getWholesalers()
      } catch (e) {
        throw new Error('Error while getting wholesealers')
      }
    },

    async getDepreciations() {
      try {
        this.depreciations = await getDepreciations()
      } catch (e) {
        throw new Error('Error while getting depreciations')
      }
    },

    async getSortings() {
      try {
        this.sortings = await getSortings()
      } catch (e) {
        throw new Error('Error while getting sortings')
      }
    },

    async getReasons() {
      try {
        this.reasons = await getReasons()
      } catch (e) {
        throw new Error('Error while getting reasons')
      }
    },

    async getDestinations() {
      try {
        this.destinations = await getDestinations()
      } catch (e) {
        throw new Error('Error while getting destinations')
      }
    }
  }
})
