import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'

export const useAppDeviceInfo = async () => {
  const deviceInfo = await Device.getInfo()

  let version
  try {
    const appInfo = await App.getInfo()
    version = appInfo.version
  } catch (e) {
    version = 'web'
  }

  return {
    platform: deviceInfo.platform,
    version
  }
}
