import { apiFetch } from '@/api'
import { NumberAccount, Pagination, PostNumberAccountDTO, StateFilters } from '@/interfaces'
import { NumberAccountFilter } from '@/store/number-account'

export const fetchGetAllNumberAccounts = async (
  filters: StateFilters & NumberAccountFilter
): Promise<Pagination<NumberAccount>> => (await apiFetch('/numberAccount', { method: 'GET', params: filters })).data

export const fetchPostNumberAccount = async (dto: PostNumberAccountDTO): Promise<NumberAccount> =>
  (await apiFetch('/numberAccount', { method: 'POST', body: dto })).data
