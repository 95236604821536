import { configure, defineRule } from 'vee-validate'
import AllRules from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'

// vee-validate locales
import es from '@vee-validate/i18n/dist/locale/es.json'

const validateDNI = (dni: string) => {
  const words = 'TRWAGMYFPDXBNJZSQVHLCKE'
  const regex = /^(\d{8})([a-z A-Z])$/

  const match = dni.match(regex)
  if (!match) {
    return false
  }

  const [, number, word] = match
  const wordIndex = parseInt(number) % 23
  const expectedWord = words.charAt(wordIndex)

  return word.toUpperCase() === expectedWord
}

const validateCIF = (cif: string) => {
  if (!cif || cif.length !== 9) {
    return false
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  const digits = cif.substr(1, cif.length - 2)
  const letter = cif.substr(0, 1)
  const control = cif.substr(cif.length - 1)
  let sum = 0
  let i
  let digit

  if (!letter.match(/[A-Z]/)) {
    return false
  }

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i])

    if (isNaN(digit)) {
      return false
    }

    if (i % 2 === 0) {
      digit *= 2
      if (digit > 9) {
        // @ts-ignore
        digit = parseInt(digit / 10) + (digit % 10)
      }

      sum += digit
    } else {
      sum += digit
    }
  }

  sum %= 10
  if (sum !== 0) {
    digit = 10 - sum
  } else {
    digit = sum
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control
  }

  return String(digit) === control || letters[digit] === control
}

// @ts-ignore
defineRule('validateCIFAndNIF', (value) => {
  if (typeof value === 'string') {
    const isValid = validateDNI(value) || validateCIF(value)
    return isValid || 'El DNI o CIF no es válido'
  }
})

// @ts-ignore
defineRule('isEqualEmail', (value, [target], ctx) => {
  if (value === ctx.form[target]) {
    return true
  }

  return 'Los campos email y repetir email deben coincidir'
})

defineRule('isEqualPassword', (value, [target], ctx) => {
  if (value === ctx.form[target]) {
    return true
  }

  return 'Los campos nueva contraseña y confirmar nueva contraseña deben coincidir'
})

// decimal rule
defineRule('decimal', (value: string) => {
  if (Number.isFinite(Number(value))) {
    return true
  }

  return 'El campo debe ser un número entero o un número decimal separado con un punto'
})

// apply rules
Object.keys(AllRules).forEach((rule: string) => defineRule(rule, AllRules[rule]))

// apply locales
configure({
  generateMessage: localize({ es })
})

setLocale('es')
