import { apiFetch } from '@/api'

import { LoginDTO, RecoverPasswordDTO, RefreshTokenDTO, UpdatePasswordDTO } from '@/interfaces/api/Auth'
import { User, UserAuth } from '@/interfaces'

export const fetchLogin = async (dto: LoginDTO): Promise<UserAuth> =>
  (await apiFetch('/user/login', { method: 'POST', body: dto })).data

export const fetchRefreshToken = async (dto: RefreshTokenDTO): Promise<UserAuth> =>
  (await apiFetch('/user/refreshToken', { method: 'POST', body: dto })).data

export const fetchRecoverPassword = async (dto: RecoverPasswordDTO): Promise<[]> =>
  (await apiFetch('/user/recoverPassword', { method: 'POST', body: dto })).data

export const fetchLogout = async (): Promise<any> => (await apiFetch('/user/logout', { method: 'POST' })).data

export const fetchUpdatePassword = async (dto: UpdatePasswordDTO): Promise<[]> =>
  (await apiFetch('/user/changePassword', { method: 'PATCH', body: dto })).data

export const fetchMe = async (): Promise<User> => (await apiFetch('/user/getMe', { method: 'GET' })).data
