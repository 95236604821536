import { createApp } from 'vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { IonicVue } from '@ionic/vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import './registerServiceWorker'

import { initDB } from './db/sqlite'

/* Cron Jobs */
import { syncJob } from '@/lib/cron-jobs'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.scss'
import './theme/qadux360.scss'

/* VeeValidation rules */
import './lib/vee-validate'

window.addEventListener('DOMContentLoaded', async () => {
  const app = createApp(App)
    .use(IonicVue, { mode: 'ios', swipeBackEnabled: false })
    .use(createPinia().use(piniaPluginPersistedstate))
    .use(router)

  await initDB()

  syncJob.start()

  router.isReady().then(() => app.mount('#app'))
})
