import { apiFetch } from '@/api'
import { Incident, Pagination, PostIncidentDTO, StateFilters } from '@/interfaces'
import { IncidentFilter } from '@/store/incident'

export const fetchGetAllIncidents = async (filters: StateFilters & IncidentFilter): Promise<Pagination<Incident>> =>
  (await apiFetch('/incident', { method: 'GET', params: filters })).data

export const fetchGetIncidentById = async (id: number): Promise<Incident> =>
  (await apiFetch(`/incident/${id}`, { method: 'GET' })).data

export const fetchPostIncident = async (dto: PostIncidentDTO): Promise<Incident> =>
  (await apiFetch('/incident', { method: 'POST', body: dto })).data
