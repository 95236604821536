import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import { useAuthStore } from '@/store/auth'
import TabsPage from '@/views/tabs/TabsView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/'
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/auth/LoginView.vue')
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPasswordView.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    meta: { protected: true },
    children: [
      {
        path: '',
        redirect: '/tabs/statistics'
      },
      {
        path: 'statistics',
        name: 'statistics',
        component: () => import('@/views/tabs/StatisticsView.vue')
      },
      {
        path: 'refunds',
        component: () => import('@/views/tabs/RefundsView.vue'),
        children: [
          {
            path: '',
            redirect: 'refunds/local'
          },
          {
            path: 'local',
            name: 'refunds-local',
            component: () => import('@/views/tabs/refunds/local/LocalView.vue')
          },
          {
            path: 'remote',
            name: 'refunds-remote',
            component: () => import('@/views/tabs/refunds/remote/RemoteView.vue')
          }
        ]
      },
      {
        path: 'utils',
        name: 'utils',
        component: () => import('@/views/tabs/UtilsView.vue')
      }
    ]
  },
  {
    path: '/incidents',
    name: 'incidents',
    component: () => import('@/views/tabs/utils/incidents/IncidentsView.vue')
  },
  {
    path: '/incidents/:id',
    name: 'incidents-detail',
    component: () => import('@/views/tabs/utils/incidents/IncidentsDetailView.vue')
  },
  {
    path: '/incidents/new',
    name: 'incidents-new',
    component: () => import('@/views/tabs/utils/incidents/NewIncidentView.vue')
  },
  {
    path: '/new-refund/step-1/general-data',
    name: 'new-refund-general-data',
    component: () => import('@/views/new-refund/GeneralForm.vue')
  },
  {
    path: '/new-refund/step-2/lines',
    name: 'new-refund-lines',
    component: () => import('@/views/new-refund/LinesList.vue')
  },
  {
    path: '/new-refund/step-2/lines/new',
    name: 'new-refund-new-line',
    component: () => import('@/views/new-refund/NewLine.vue')
  },
  {
    path: '/new-refund/step-2/lines/edit/:id',
    name: 'new-refund-edit-line',
    component: () => import('@/views/new-refund/EditLine.vue')
  },
  {
    path: '/new-refund/step-3/signatures',
    name: 'new-refund-signatures',
    component: () => import('@/views/new-refund/ConfirmSignatures.vue')
  },
  {
    path: '/new-refund/step-4/attached-files',
    name: 'new-refund-attached-files',
    component: () => import('@/views/new-refund/AttachedFiles.vue')
  },
  {
    path: '/new-refund/step-5/tag-code',
    name: 'new-refund-tag-code',
    component: () => import('@/views/new-refund/TagCode.vue')
  },
  {
    path: '/new-refund/step-6/additional-data',
    name: 'new-refund-additional-data',
    component: () => import('@/views/new-refund/AdditionalData.vue')
  },
  {
    path: '/new-sample/step-1/general-data',
    name: 'new-sample-general-data',
    component: () => import('@/views/new-sample/GeneralForm.vue')
  },
  {
    path: '/new-sample/step-2/lines',
    name: 'new-sample-lines',
    component: () => import('@/views/new-sample/LinesList.vue')
  },
  {
    path: '/new-sample/step-2/lines/new',
    name: 'new-sample-new-line',
    component: () => import('@/views/new-sample/NewLine.vue')
  },
  {
    path: '/new-sample/step-2/lines/edit/:id',
    name: 'new-sample-edit-line',
    component: () => import('@/views/new-sample/EditLine.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to: RouteLocationNormalized) => {
  const authStore = useAuthStore()

  const canAccess = to.meta.protected ? authStore.isUserLoggedIn : true
  if (!canAccess) {
    return '/auth/login'
  }
})

export default router
