import * as cryptojs from 'crypto-js'

export const encrypt = (string: string): string | null => {
  const salt = process.env.VUE_APP_CRYPTO_SALT
  if (!salt) {
    return null
  }

  return cryptojs.AES.encrypt(string, salt).toString()
}

export const decrypt = (string: string): string | null => {
  const salt = process.env.VUE_APP_CRYPTO_SALT
  if (!salt) {
    return null
  }

  const bytes: cryptojs.lib.WordArray = cryptojs.AES.decrypt(string, salt)
  return bytes.toString(cryptojs.enc.Utf8)
}
