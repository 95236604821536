export const DATABASE = 'qadux360'

export const schemaToImport = {
  database: DATABASE,
  version: 2,
  encrypted: false,
  mode: 'full',
  tables: [
    {
      name: 'client',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'code', value: 'TEXT NOT NULL' },
        { column: 'name', value: 'TEXT NOT NULL' },
        { column: 'email', value: 'TEXT NOT NULL' },
        { column: 'division', value: 'TEXT NOT NULL' },
        {
          constraint: 'PK_client_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'product',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'brand', value: 'TEXT NOT NULL' },
        { column: 'capLot', value: 'TEXT NOT NULL' },
        { column: 'depreciation', value: 'BIGINT NOT NULL' },
        { column: 'description', value: 'TEXT NOT NULL' },
        { column: 'division', value: 'TEXT NOT NULL' },
        { column: 'ean', value: 'TEXT NOT NULL' },
        { column: 'nationalCode', value: 'TEXT NOT NULL' },
        { column: 'root', value: 'TEXT NOT NULL' },
        { column: 'price', value: 'DOUBLE NOT NULL' },
        {
          constraint: 'PK_product_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'lotNumber',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'code', value: 'TEXT NOT NULL' },
        { column: 'ean', value: 'TEXT NOT NULL' },
        { column: 'batch', value: 'TEXT NOT NULL' },
        { column: 'expiration', value: 'TEXT NOT NULL' },
        {
          constraint: 'PK_lotNumber_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'depreciation',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'name', value: 'TEXT NOT NULL' },
        { column: 'orderBy', value: 'BIGINT NOT NULL' },
        {
          constraint: 'PK_depreciation_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'type',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'name', value: 'TEXT NOT NULL' },
        { column: 'orderBy', value: 'BIGINT NOT NULL' },
        {
          constraint: 'PK_type_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'destination',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'name', value: 'TEXT NOT NULL' },
        { column: 'orderBy', value: 'BIGINT NOT NULL' },
        {
          constraint: 'PK_destination_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'reason',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'name', value: 'TEXT NOT NULL' },
        { column: 'orderBy', value: 'BIGINT NOT NULL' },
        {
          constraint: 'PK_reason_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'observation',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'name', value: 'TEXT NOT NULL' },
        { column: 'orderBy', value: 'BIGINT NOT NULL' },
        {
          constraint: 'PK_observation_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'wholesaler',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'name', value: 'TEXT NOT NULL' },
        { column: 'orderBy', value: 'BIGINT NOT NULL' },
        {
          constraint: 'PK_wholesaler_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'sorting',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'name', value: 'TEXT NOT NULL' },
        { column: 'orderBy', value: 'BIGINT NOT NULL' },
        {
          constraint: 'PK_sorting_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'sync_data',
      schema: [
        { column: 'table_name', value: 'TEXT NOT NULL' },
        { column: 'version', value: 'BIGINT NOT NULL' },
        { column: 'label', value: 'TEXT NOT NULL' },
        { column: 'orderBy', value: 'BIGINT NOT NULL' },
        {
          constraint: 'PK_versions_id',
          value: 'PRIMARY KEY (table_name) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'pending_refunds',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'numdev', value: 'TEXT NOT NULL' },
        { column: 'tagCodes', value: 'TEXT' },
        { column: 'clientCode', value: 'TEXT' },
        { column: 'clientName', value: 'TEXT' },
        { column: 'datetime', value: 'DATETIME' },
        { column: 'type', value: 'TEXT' },
        { column: 'email', value: 'TEXT' },
        { column: 'wholesaler', value: 'TEXT' },
        { column: 'authorization', value: 'BOOLEAN' },
        { column: 'observation', value: 'TEXT' },
        { column: 'comment', value: 'TEXT' },
        { column: 'packageQuantity', value: 'INTEGER' },
        { column: 'clientSignature', value: 'TEXT' },
        { column: 'userSignature', value: 'TEXT' },
        { column: 'attachment1', value: 'TEXT' },
        { column: 'attachment2', value: 'TEXT' },
        { column: 'attachment3', value: 'TEXT' },
        { column: 'finished', value: 'BOOLEAN' },
        { column: 'virtual', value: 'BOOLEAN' },
        {
          constraint: 'PK_pending_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        }
      ]
    },
    {
      name: 'pending_refunds_lines',
      schema: [
        { column: 'id', value: 'INTEGER' },
        { column: 'idRefund', value: 'INTEGER' },
        { column: 'ean', value: 'TEXT' },
        { column: 'nc', value: 'TEXT' },
        { column: 'description', value: 'TEXT' },
        { column: 'lotNumber', value: 'TEXT' },
        { column: 'createdLot', value: 'BOOLEAN' },
        { column: 'expirationDate', value: 'TEXT' },
        { column: 'lotImage', value: 'TEXT' },
        { column: 'destination', value: 'TEXT' },
        { column: 'reason', value: 'TEXT' },
        { column: 'sorting', value: 'TEXT' },
        { column: 'units', value: 'DOUBLE' },
        { column: 'price', value: 'DOUBLE' },
        { column: 'depreciation', value: 'DOUBLE' },
        { column: 'amount', value: 'DOUBLE' },
        { column: 'eanReplacement', value: 'TEXT' },
        { column: 'ncReplacement', value: 'TEXT' },
        { column: 'descriptionReplacement', value: 'TEXT' },
        { column: 'unitsReplacement', value: 'TEXT' },
        {
          constraint: 'PK_pending_refunds_lines_id',
          value: 'PRIMARY KEY (id) ON CONFLICT REPLACE'
        },
        {
          foreignkey: 'idRefund',
          value: 'REFERENCES pending_refunds(id) ON DELETE CASCADE'
        }
      ]
    }
  ]
}
