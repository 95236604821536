import {Filesystem, Directory} from '@capacitor/filesystem'
import {readAsBase64} from '@/lib/camera'

export const writeFile = async (webPath: string, isBase64 = true): Promise<string | null> => {
  try {

    let filename
    let base64Data = ''
    if (isBase64) {
      base64Data = webPath
      filename = new Date().getTime() + '.jpeg'
    } else {
      const base64Data = await readAsBase64(webPath)
      const init = base64Data.substring(0, 10)
      if (init === 'data:image') {
        filename = new Date().getTime() + '.jpeg'
      } else {
        filename = new Date().getTime() + '.pdf'
      }
    }

    await Filesystem.writeFile({
      path: filename,
      data: base64Data,
      directory: Directory.Data
    })

    return filename
  } catch
    (e) {
    console.log(e)
    return null
  }
}

export const readFile = async (filename: string): Promise<string | null> => {
  try {
    const readFile = await Filesystem.readFile({
      path: filename,
      directory: Directory.Data
    })

    return `data:image/jpeg;base64,${readFile.data}`
  } catch (e) {
    console.log(e)
    return null
  }
}

export const deleteFile = async (filename: string): Promise<void> => {
  try {
    await Filesystem.deleteFile({
      path: filename,
      directory: Directory.Data
    })
  } catch (e) {
    console.log(e)
  }
}
