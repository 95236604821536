export const REMOTE = 'remote'
export const LOCAL = 'local'

export const SUBSTITUTION = 'Sustitución'

export const LineCodeType = {
  EAN: 1,
  NC: 2,
  list: [
    {
      text: 'EAN',
      value: 1
    },
    {
      text: 'Código nacional',
      value: 2
    }
  ]
}
