import { defineStore } from 'pinia'

type ControlState = {
  loading: boolean
  isAppContentHidden: boolean
  error: string | null
  message: string | null
}

export const useControlStore = defineStore({
  id: 'control',
  state: (): ControlState => ({
    loading: false,
    isAppContentHidden: false,
    error: null,
    message: null
  }),
  actions: {
    showAppContent() {
      this.isAppContentHidden = false
    },

    hideAppContent() {
      this.isAppContentHidden = true
    }
  }
})
